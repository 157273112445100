<template>
  <div class="app-container">
    <el-form ref="user" :model="user" :rules="validateRules" label-width="120px">
      <el-form-item label="用户名" prop="name">
        <el-input v-model="user.name"/>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="user.mobile"/>
      </el-form-item>
      <el-form-item label="用户密码" prop="password">
        <el-input v-model="user.password"/>
      </el-form-item>


      <el-form-item>
        <el-button :disabled="saveBtnDisabled" type="primary" @click="saveOrUpdate">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import userApi from '@/api/acl/user'

const defaultForm = {
  name: '',
  mobile: '',
  password: ''
}

const validatePass = (rule, value, callback) => {
  if (value.length < 6) {
    callback(new Error('密码不能小于6位'))
  } else {
    callback()
  }
}
import md5 from 'js-md5'
export default {
  data() {
    return {
      isUser: true,
      user: defaultForm,
      saveBtnDisabled: false, // 保存按钮是否禁用,
      validateRules: {
        name: [{ required: true, trigger: 'blur', message: '用户名必须输入' }],
        mobile: [{ required: true, trigger: 'blur', message: "手机号码必须输入" }],
        password: [{ required: true, trigger: 'blur', validator: validatePass }]
      }
    }
  },

  // 监听器
  watch: {
    $route(to, from) {
      console.log('路由变化......')
      console.log(to)
      console.log(from)
      this.init()
    }
  },

  // 生命周期方法（在路由切换，组件不变的情况下不会被调用）
  created() {
    console.log('form created ......')
    this.init()
  },

  methods: {

    // 表单初始化
    init() {
      if (this.$route.params && this.$route.params.id) {
        const id = this.$route.params.id;
        this.isUser = false;
        this.fetchDataById(id)
      } else {
        // 对象拓展运算符：拷贝对象，而不是赋值对象的引用
        this.user = { ...defaultForm }
      }
    },

    saveOrUpdate() {
      this.$refs.user.validate(valid => {
        if (valid) {
          this.saveBtnDisabled = true // 防止表单重复提交
          if (!this.user.id) {
            this.saveData()
          } else {
            this.updateData()
          }
        }
      })
    },

    // 新增讲师
    saveData() {
      userApi.save({name:this.user.name,mobile:this.user.mobile,password:md5(this.user.password)}).then(response => {
        // debugger
        if (response.data.sueecss) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.$router.push({ path: '/acl/user/list' })
        }
      })
    },

    // 根据id更新记录
    updateData() {
      // teacher数据的获取
      var par = {id:this.user.id,name:this.user.name,mobile:this.user.mobile};
      if ("******" != this.user.password){
        par.password=md5(this.user.password);
      }
      userApi.updateById(par).then(response => {
        if (response.data.sueecss) {
          this.$message({
            type: 'success',
            message: response.message
          })
          this.$router.push({ path: '/acl/user/list' })
        }else {
          this.$message.error("更新失败");
          this.$router.push({ path: '/acl/user/list' })
        }
      })
    },

    // 根据id查询记录
    fetchDataById(id) {
      userApi.getById(id).then(response => {
        // debugger
        this.user = response.data.data.user;
        this.user.password="******";
      })
    }

  }
}
</script>
